import React, { useState, useEffect } from "react";
import PDFIcon from "./pdfIcon";
import PDF from "./pdf";
import useWindowWidth from "./useWindowWidth";
import Loading from "./loading";
import { useParams } from "react-router-dom";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const PdfAssessmentPageContainer = ({ viewing }) => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const [pdf, setPdf] = useState("");
    const width = useWindowWidth(1000);
    const { courseCode, assessmentCode } = useParams();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);

    useEffect(() => {
        const fetchPdf = async () => {
            try {
                setRequestLifeCycle(true);   
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-submission-pdf-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.status === 200) {
                    const blob = await res.blob();
                    const fileReader = new FileReader();
                    fileReader.onload = (e) => {
                        const pdfDataUrl = e.target.result;
                        setPdf(pdfDataUrl); // This will store the PDF data as base64
                    };
                    fileReader.readAsDataURL(blob);
                } else if (res.status === 401) {
                    navigateToLoginPageEnv();
                }

            } catch (error) { 
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        };

        fetchPdf();

    }, [])

    return (
        <>
            {

            viewing

            ?
                
                <div className={ `pdf-content-container  ${ width ? "small" : "" }` } style={{ minHeight: width ? "600px" : "", position: "relative"}}>
                    {
                        requestLifeCycle

                        ?

                        <div className="loading-zip-container">
                            <Loading />
                        </div>

                        :

                        <>
                            {
                                pdf === "" 
            
                                ?
            
                                <div className="loading-zip-container">
                                    <PDFIcon />
                                </div>
            
                                :
            
                                <>
                                    <PDF pdf={pdf}/>
                                </>
                            }
                        </>
                    }
                </div>

            :
                <div className={ `pdf-content-container false ${ width ? "small" : "" }` }>
                    <PDFIcon />
                    Your Instructor has submission viewing disabled
                </div>

            }
        </>
    );
}

export default PdfAssessmentPageContainer;