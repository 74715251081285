import React from "react";
import MonacoEditor from "@monaco-editor/react";
import { languageMap } from "./languagemap";
import useWindowWidth from "./useWindowWidth";
import DownloadButton from "./downloadButton";
import DarkTheme from "./darkTheme";

const FileEditor = ({ currentFileData, currentFilename, blob, updateIsDarkTheme, isDarkTheme }) => {
    const bool = useWindowWidth(1000);

    const getLanguageByExtension = (filename) => {
        if (filename !== undefined) {
            const extension = filename.split('.').pop().toLowerCase();
            return languageMap[extension] || 'plaintext';
        } else {
            return "";
        }
    };

    return (
        <div className="editor-container" style={{ width: bool ? "100%" : "", height: bool ? "600px" : "" }}>
            <MonacoEditor
                language={getLanguageByExtension(currentFilename)}
                theme={ isDarkTheme ? "vs-dark" : "vs"}
                value={currentFileData}
                options={{
                    readOnly: true,
                    fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                    fontSize: 14, // Default font size (14px)
                    tabSize: 8,         // Set tab length to 4 spaces
                    fontWeight: "500", // Default font weight
                }}
            />
            {
                currentFilename !== "" && 
                <div className="file-name-header">
                    { currentFilename }
                </div>
            }
            <DownloadButton blob={blob}/>
            <DarkTheme updateIsDarkTheme={updateIsDarkTheme} isDarkTheme={isDarkTheme}/>
        </div>
    );
};

export default FileEditor;