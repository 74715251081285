import React from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";

const FeedbackWindow = (props) => {
    return (
        <>
            <div className="feedback-window-container">
                <MarkdownWithLaTeX content={props.feedback}/>
            </div>
        </>
    );
}

export default FeedbackWindow;