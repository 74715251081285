function formatTime(minutes) {
    const days = Math.floor(minutes / 1440);  // 1440 minutes in a day
    minutes %= 1440;
    const hours = Math.floor(minutes / 60);   // 60 minutes in an hour
    minutes %= 60;
  
    let result = [];
  
    if (days > 0) result.push(`${days} day${days > 1 ? 's' : ''}`);
    if (hours > 0) result.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    
    result.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`); // Always include minutes
  
    return result.join(', ');
}

export default formatTime;
  
  