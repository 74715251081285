import React from "react";

const Unenroll = () => {
    return (
        <>
            <div className="icon-3-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                    <path fill="#3C3C3C" stroke="#3C3C3C" strokeWidth="1" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
                    <path fill="#3C3C3C" stroke="#3C3C3C" strokeWidth="1" d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
                </svg>
            </div>
            
        </>
    );
}

export default Unenroll;