import React from "react";

const NavInfoAssessment = (props) => {
    return (
        <>
            <div className="semester-nav-info">
                <div className="side-nav-info-header">
                    { props.name }
                </div>
                <div className="text-side-nav">
                    See information regarding your evaluation to the right. Upon submission, you may see your submitted evaluation if your instructor has enabled document viewing.
                </div>
            </div>
        </>
    );
}

export default NavInfoAssessment;