import React from "react";
import PDFIcon from "./pdfIcon";
import PdfAssessmentPageContainer from "./pdfAssessmentPageContainer";
import useWindowWidth from "./useWindowWidth";

const PdfAssessmentPageContainerWrapper = ({ assessmentInformation }) => {
    const width = useWindowWidth(1000);

    return (
        <>
            {

                Object.keys(assessmentInformation).length !== 0

                ?
                    <>
                        <PdfAssessmentPageContainer viewing={assessmentInformation.viewing}/>
                    </>

                :
                    <div className={ `pdf-content-container ${ width ? "small" : "" }` }>
                        <div className="loading-zip-container">
                            <PDFIcon />
                        </div>
                    </div>
            }
        </>
    );
}

export default PdfAssessmentPageContainerWrapper;