import React from "react";
import AddCourse from "./addCourse";
import Person from "./person";
import LogoFill from "./logoFill";
import useWindowWidth from "./useWindowWidth";
import { useNavigate, useParams } from "react-router-dom";

const Nav = (props) => {
    const width = useWindowWidth(400);
    const navigate = useNavigate();
    const { courseCode, assessmentCode } = useParams();

    const handleClick = () => {
        navigate(`/${courseCode}/${assessmentCode}/evaluation`);
    }

    const isDesktop = process.env.REACT_APP_IS_DESKTOP === 'true';

    return (
        <>
            <div className="nav-container">
                <div className="basic-container">
                    <LogoFill open={props.open}/>
                    <Person open={props.open}/>
                </div>
                { props.bool && <AddCourse numCourses={props.numCourses} open={props.open}/> }
                {
                    props.boolTwo && isDesktop

                    ?

                    <>
                        <div className="top-right-item" style={{ width: width ? "40px" : "140px" }} onClick={handleClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z"/>
                            </svg>
                            { width ? "" : "Attempt" }
                        </div>
                    </>

                    :

                    <>
                    </>

                }
            </div>

        </>
    );
}

export default Nav;