import React from "react";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "./useWindowWidth";

const LogoFill = (props) => {
    const width = useWindowWidth(365);
    const navigate = useNavigate();

    const handleLogoClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            // Open in a new tab
            window.open(`/`, '_blank');
        } else {
            // Navigate in the same tab  
            navigate(`/`);
        }
    }

    return (
        <>
            <div className={ props.open ? width ? "icon-2-responsive open" : "icon-2-responsive" : "icon-2"}>
                <div className="block-2" onClick={handleLogoClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 16 16">
                        <path fill="#0B73FE" stroke="#0B73FE" strokeWidth=".3" d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z"/>
                    </svg>
                </div>
            </div>
        </>
    );
}

export default LogoFill;