import React, { useState, useEffect } from "react";
import FileEditor from "./fileEditor";
import FileTreeVisualizer from "./fileTreeVisualizer";
import Loading from "./loading";
import useWindowWidth from "./useWindowWidth";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const ZipFileViewerGradingPage = ({ contents, blob, requestLifeCycle }) => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const [currentFilename, setCurrentFilename] = useState("");
    const [currentFileData, setCurrentFileData] = useState("");
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [initCall, setInitCall] = useState(false);
    const width = useWindowWidth(1000);

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    navigateToLoginPageEnv();
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateCurrentFilename(filename) {
        setCurrentFilename(filename);
    }

    function updateCurrentFileData(data) {
        setCurrentFileData(data);
    }

    function updateIsDarkTheme(theme) {
        setIsDarkTheme(theme);
        submitForm(theme);
    }

    const submitForm = async (theme) => {
        if (initCall) {
            try {
                const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/update-theme";
                const urlOptions = {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        isDarkTheme: theme
                    })
                }
    
                const response = await fetch(url, urlOptions);
                const data = response.json();
                console.log(data);
                if (response.status === 401) {
                    navigateToLoginPageEnv();
                }
    
            } catch(error) {
                console.log(error);
            }
        } else {
            setInitCall(false);
        }
    }

    return (
        <>
            <div className={ `pdf-content-container  ${ width ? "small" : "" }` } style={{ minHeight: width ? "800px" : "" , position: "relative"}}>
                <div className="editor-tree-container" style={{ flexDirection: width ? "column" : "", color: isDarkTheme? "var(--vs-icon)" : "var(--almost-black)", backgroundColor: isDarkTheme ? "var(--dark-background)" : "var(--gray-three)" }}>
                    {
                        requestLifeCycle

                        ?

                        <div className="loading-zip-container">
                            <Loading />
                        </div>

                        :

                        <>
                            <FileTreeVisualizer contents={contents} updateCurrentFilename={updateCurrentFilename} updateCurrentFileData={updateCurrentFileData}/>
                            <FileEditor isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} blob={blob} currentFileData={currentFileData} currentFilename={currentFilename}/>
                        </>

                    }
                </div>
            </div>
        </>
    );
}

export default ZipFileViewerGradingPage;