import React, { useState } from "react";
import Nav from "./nav";
import SideNav from "./sideNav";

const Navigation = (props) => {
    const [open, setOpen] = useState(false);

    function updateOpen(open) {
        setOpen(open);
    }

    return (
        <>
            <Nav boolTwo={props.boolTwo} numCourses={props.numCourses} updateOpen={updateOpen} open={open} bool={props.bool}/>
            <SideNav updateOpen={updateOpen} open={open} info={props.info} infoCollapsed={props.infoCollapsed}/>
        </>
    );
}

export default Navigation;