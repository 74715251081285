import React from "react";

const Loading = (props) => {
    return (
        <>
            <div className="loading-dots">
                <div className="dot" style={{ backgroundColor: props.white ? "white" : "#333" }}></div>
                <div className="dot" style={{ backgroundColor: props.white ? "white" : "#333" }}></div>
                <div className="dot" style={{ backgroundColor: props.white ? "white" : "#333" }}></div>
            </div>
        </>
    );
}

export default Loading;