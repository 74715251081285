import { useNavigate } from "react-router-dom";

// Custom hook for navigation
const useNavigateToLoginPageEnv = () => {
    const navigate = useNavigate();

    const navigateToLoginPage = () => {
        if (process.env.REACT_APP_IS_DESKTOP === "true") {                        
            navigate(`/login`);
        } else {
            window.location.href = process.env.REACT_APP_401_REDIRECT_URL;
        }
    };

    return navigateToLoginPage;
};

export default useNavigateToLoginPageEnv;