import React, { useState, useEffect } from "react";
import SquareWithTextView from "./squareWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import SquareWithTextViewGradingPage from "./squareWithTextViewGradingPage";

const SelectAllThatApplyView = ({ questionNumber, question, points, options, grading, updateQuestionsToSave, uuid, answerArrayPassed, pointsEarned, studentAnswer, answerArrayAnswer }) => {
    const [answerArray, setAnswerArray] = useState(answerArrayPassed);
    const [initCall, setInitCall] = useState(true);

    function updateIndex(newIndex) {
        if (answerArray.includes(newIndex)) {
            setAnswerArray(answerArray.filter(index => index !== newIndex)); // Remove newIndex
        } else {
            setAnswerArray([...answerArray, newIndex]); // Add newIndex
        }
    }

    useEffect(() => {
        // init checking
        if (initCall) {
            setInitCall(false);
            return;
        }

        // construct new body
        const body = {
            answerArray: answerArray
        };

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
        ...prev,
        [uuid]: body
        }))

    }, [answerArray])

    const studentAnswerIsAnOptionAndIsInAnswerArray = (arrayOfAnswers, arrayOfStudentAnswers, answerToLookFor) => {
        if (!arrayOfStudentAnswers.includes(answerToLookFor)) {
            return false;
        } else if (arrayOfAnswers.includes(answerToLookFor)) {
            return true;
        }
    }

    const handleStateCalculation = (answerState) => {
        const studentAnswerIsInAnswerArray = studentAnswerIsAnOptionAndIsInAnswerArray(answerArrayAnswer, studentAnswer, answerState)
        // check if answers match or check if they match the possible answerState
        if (studentAnswerIsInAnswerArray && (answerArrayAnswer.includes(answerState))) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (!studentAnswerIsInAnswerArray && (studentAnswer.includes(answerState))) {
            return 0;
        }
        // if answer but student not correct
        else if (!studentAnswerIsInAnswerArray && (answerArrayAnswer.includes(answerState))) {
            return 2
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} />
                </div>
                <div className="select-all-that-apply-array">
                    {
                        options && options.length > 0

                        ?

                        options.map((option) => (
                            grading
                            
                            ?

                            <SquareWithTextViewGradingPage key={option.index} state={handleStateCalculation(option.index)} text={option.option}/>      

                            :
                            
                            <SquareWithTextView key={option.index} correct={answerArray.includes(option.index)} index={option.index} text={option.option} updateIndex={updateIndex}/>
                        ))

                        :

                        <>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default SelectAllThatApplyView;