import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import DarkThemeQuestion from "./darkThemeQuestion";
import MarkdownWithLaTeX from "./markDownWithLatex";

const CodeResponseView = ({ useSyntaxHighlighting, updateQuestionsToSave, updateContents, location, contents, useAutocompletion, questionNumber, question, language, isDarkTheme, updateIsDarkTheme, points, grading, uuid, answerPassed, pointsEarned }) => {
    const [answer, setAnswer] = useState(answerPassed);
    const [contentState, setContentState] = useState("");
    const [initCall, setInitCall] = useState(true);
    const [initCallTwo, setInitCallTwo] = useState(true);

    useEffect(() => {
        // Check if contents and location are valid
        if (contents && location && contents[location]?.content) {
            setInitCallTwo(true);
            setContentState(contents[location].content);
        }
    }, [contents, location]); // Depend on contents and location

    const handleChange = (value) => {
        if (!grading) {
            updateContents((prev) => ({
                ...prev,
                [location]: {
                    content: value,
                    isDir: false
                }
            }));
        }
    }

    const handleAnswer = (value) => {
        setAnswer(value);
    }

    const handleContentState = (content) => {
        setContentState(content);
    }

    useEffect(() => {
        // init checking
        if (initCall) {
            setInitCall(false);
            return;
        }

        // Create a timer to delay the update by 0.5 seconds
        const timer = setTimeout(() => {
            // Construct new body
            const body = {
                answer: answer
            };

            // Update questions to save
            if (!grading) {
                updateQuestionsToSave((prev) => ({
                    ...prev,
                    [uuid]: body
                }));
            }
        }, 500); // 500ms delay

        // Clear the timer if `answer` changes again before 0.5 seconds
        return () => clearTimeout(timer);
    }, [answer]);

    useEffect(() => {
        // init checking
        if (initCallTwo) {
            setInitCallTwo(false);
            return;
        }

        // Create a timer to delay the update by 0.5 seconds
        const timer = setTimeout(() => {
            handleChange(contentState)
        }, 500); // 500ms delay

        // Clear the timer if `answer` changes again before 0.5 seconds
        return () => clearTimeout(timer);
    }, [contentState]);

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) +" / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} />
                </div>
                <div className="code-response-area-wrapper">
                    <div className="code-response-area" style={{ backgroundColor: isDarkTheme ? "var(--vs-background)" : "white", color: isDarkTheme ? "white" : "var(--almost-black)" }}>
                        <div className="code-editor-header">
                            <DarkThemeQuestion isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme}/>
                        </div>                        
                        <Editor
                            language={useSyntaxHighlighting ? language : "plaintext"}
                            value={contents && location in contents ? contentState : answer}
                            theme={ isDarkTheme ? "vs-dark" : "vs"}
                            onChange={contents && location in contents ? handleContentState : handleAnswer}
                            options={{
                                fontFamily: "Consolas, 'Courier New', monospace", // Default font family
                                fontSize: 14, // Default font size (14px)
                                tabSize: 8,         // Set tab length to 4 spaces
                                fontWeight: "500", // Default font weight
                                suggestOnTriggerCharacters: useAutocompletion,
                                quickSuggestions: useAutocompletion,
                                readOnly: grading
                            }}
                        />
                        <div className="code-editor-header" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CodeResponseView;