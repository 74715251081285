import React from "react";

const Instructor = () => {
    return (
        <>
            <div className="icon-3" style={{ border: "none", cursor: "auto" }}>
                <div className="icon-3-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" viewBox="0 0 16 16">
                        <path fill="#3C3C3C" stroke="#3C3C3C" strokeWidth=".3" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                    </svg>
                </div>
            </div>
        </>
    );
}

export default Instructor