import React, { useState, useEffect } from "react";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import WhiteLoading from "./whiteLoading";
import ASingleTestGrading from "./aSingleTestGrading";

const TestsViewerGrading = ({ requestLifeCycle, gradedQuestions, testsConfigDict, updateCurrentViewer }) => {
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        if (gradedQuestions && gradedQuestions.length > 0) {
            setQuestions(gradedQuestions.filter((question, index) => {
                question.index = index;
                return (question.questionType === TypesOfQuestionsEnum.PROGRAMMING) && question.tests.length > 0
            }))
        }
    }, [gradedQuestions])

    return (
        <>
            {
                requestLifeCycle

                ?

                <div className="loading-zip-container">
                    <WhiteLoading />
                </div>

                :

                <>
                    {
                        questions && questions.length > 0

                        ?

                        <>
                            <div className="question-tests-container">
                                {
                                    questions.map((question) => (
                                        question.tests.map((id) => {
                                            if (id in testsConfigDict) {
                                                return <ASingleTestGrading key={id} {...testsConfigDict[id]} index={question.index + 1}/>
                                            }
                                        })
                                    ))
                                }
                            </div>
                        </>

                        :

                        <>
                            <div className="no-questions">
                                <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                                    <div className="no-assignments"> 
                                        There are currently no autograde tests for any questions
                                        <div className="save-changes-2" onClick={() => {
                                            updateCurrentViewer(TypesOfLocationEnum.CUSTOM_EXAM);
                                        }}>
                                            Back To Questions
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </>

            }
        </>
    );
}

export default TestsViewerGrading;