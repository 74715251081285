import moment from 'moment-timezone';

const convertDate = (dateInput, deleteYearBool) => {
    // Parse the input date in the provided reference timezone
    const localDate = moment.utc(dateInput).local();

    // Define options for formatting without commas
    let formatString = 'MMM D, h:mm A'; // Default format with no year
    if (!deleteYearBool) {
        formatString = 'MMM D YYYY, h:mm A'; // Include year if deleteYearBool is false
    }

    // Format the date
    let formattedDate = localDate.format(formatString);

    // Replace commas with 'at'
    formattedDate = formattedDate.replace(/,/g, ' at');

    // Return the formatted date
    return formattedDate;
};

export default convertDate;