import React from "react";
import useWindowWidth from "./useWindowWidth";

const SaveChanges = (props) => {
    const width = useWindowWidth(410);

    return (
        <>
            <div className="save-changes-container">
                <div 
                    className="cancel" 
                    type="reset"                      
                    onClick={props.resetForm} 
                >
                    {props.left}
                </div>
                <div 
                    className="save-changes"                     
                    onClick={props.submitForm}
                >
                    {width ? props.right.small : props.right.large}
                </div>
            </div>
        </>
    );
}

export default SaveChanges;
