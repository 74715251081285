import React, { useState, useEffect } from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";

const TextResponseView = ({ questionNumber, question, points, grading, updateQuestionsToSave, uuid, studentAnswer, pointsEarned }) => {
    const [answer, setAnswer] = useState(studentAnswer);
    const [initCall, setInitCall] = useState(true);

    function updateAnswer(e) {
        setAnswer(e.target.value);
    }

    
    useEffect(() => {
        // init checking
        if (initCall) {
            setInitCall(false);
            return;
        }
        
        // Create a timer to delay the update by 0.5 seconds
        const timer = setTimeout(() => {
            // Construct new body
            const body = {
                answer: answer
            };

            // Update questions to save
            updateQuestionsToSave((prev) => ({
                ...prev,
                [uuid]: body
            }));
        }, 500); // 500ms delay

        // Clear the timer if `answer` changes again before 0.5 seconds
        return () => clearTimeout(timer);
    }, [answer]);

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">                        
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} />
                </div>
                <div className="text-response-area">
                    <textarea className="text-response-area-input" value={answer} onChange={updateAnswer}/>
                </div>
            </div>
        </>
    );
}

export default TextResponseView;