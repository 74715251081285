import React from "react";
import CustomExamWrapperView from "./customExamWrapperView";
import { MathJaxContext } from "better-react-mathjax";
import mathJaxConfig from "./mathJaxConfig";
import Loading from "./loading";

const CustomExamViewGradedWrapper = ({ contents, gradedQuestions, requestLifeCycle }) => {
    console.log(gradedQuestions)
    return (
        <>
            {

                requestLifeCycle

                ?

                <div className="loading-zip-container">
                    <Loading />
                </div>

                :

                <>
                    <div className="custom-programming-exam-wrapper" style={{ position: "relative" }}>
                        <MathJaxContext config={mathJaxConfig}>
                            <CustomExamWrapperView useSyntaxHighlighting={true} contents={contents} questions={gradedQuestions} grading={true}/>
                        </MathJaxContext>
                    </div>
                </>

            }
        </>
    );
}

export default CustomExamViewGradedWrapper;