// Function to group and sort courses
const ReformatCourses = (courses) => {
    const semesters = {};

    // Group courses by term and year
    courses.forEach(course => {
        const termYear = `${course.term} ${course.year}`;
        if (!semesters[termYear]) {
            semesters[termYear] = [];
        }
        semesters[termYear].push({
            name: course.name,
            description: course.description,
            courseCode: course.courseCode,
            color: course.color
        });
    });

    // Sort terms in reverse chronological order and by Spring, Fall, Summer
    const termOrder = ["Winter", "Fall", "Summer", "Spring"];
    const sortedSemesters = Object.keys(semesters).sort((a, b) => {
        const [termA, yearA] = a.split(' ');
        const [termB, yearB] = b.split(' ');
        if (yearA !== yearB) {
            return yearB - yearA;
        }
        return termOrder.indexOf(termA) - termOrder.indexOf(termB);
    });

    // Format the final output
    return sortedSemesters.map(termYear => ({
        time: termYear,
        courses: semesters[termYear]
    }));
};

export default ReformatCourses;