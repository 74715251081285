import React, { useState, useEffect } from "react";
import Navigation from "./navigation";
import useScrollToTop from "./useScrollToTop";
import NavInfoAssessment from "./navInfoAssessment";
import useWindowWidth from "./useWindowWidth";
import LeftInfoAssessmentPage from "./leftInfoAssessmentPage";
import PdfAssessmentPageContainerWrapper from "./pdfAssessmentPageContainerWrapper";
import ZipFileViewer from "./zipFileViewer";
import LocationSettingsHover from "./locationSettingsHover";
import { useParams } from "react-router-dom";
import TypesOfLocationEnum from "./typesOfLocationEnum";
import CustomExamViewGradedWrapper from "./customExamViewGradedWrapper";
import parseZipFile from "./parseZipFile";
import parseFileStructure from "./parseFileStructure";
import TestsViewerGrading from "./testsViewerGrading";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const AssessmentPage = () => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    useScrollToTop();
    const width = useWindowWidth(1000);
    const [currentViewer, setCurrentViewer] = useState(TypesOfLocationEnum.SETTINGS)
    const [assessmentInformation, setAssessmentInformation] = useState({});
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const { courseCode, assessmentCode, email } = useParams();
    const [contents, setContents] = useState([]);
    const [contentsForCustomExam, setContentsForCustomExam] = useState({});
    const [blob, setBlob] = useState();
    const [requestLifeCycleTwo, setRequestLifeCycleTwo] = useState(false);
    const [zipFileExists, setZipFileExists] = useState(false);
    const [gradedQuestions, setGradedQuestions] = useState([]);
    const [testsConfigDict, setTestsConfigDict] = useState({});

    useEffect(() => {
        const fetchAssessmentInformation = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-submission-settings-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                console.log(data)
                if (res.status === 200) {
                    setAssessmentInformation(data);
                    updateAssessmentInformation(data);
                    setZipFileExists('submission' in data ? data.submission.zipFileExists : false)
                    setGradedQuestions('submission' in data ? data.submission.gradedQuestions : [])
                }  else if (res.status === 401) {
                    navigateToLoginPageEnv();
                }
        
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        fetchAssessmentInformation();
    }, [])

    useEffect(() => {
        const fetchZip = async () => {
            try {
                setRequestLifeCycleTwo(true)
                const res = await fetch( process.env.REACT_APP_SUBMISSION_API_URL  + `/get-submission-zip-student/${courseCode}/${assessmentCode}`, {
                    method: "GET",
                    credentials: "include"
                });
                if (res.status === 200) {
                    const blob = await res.blob(); // Get the ZIP as a blob
                    setBlob(blob);
                    const contents = await parseZipFile(blob);
                    setContents(contents); 
                    const parsedContents = parseFileStructure(contents)
                    setContentsForCustomExam(parsedContents)
                    const testsConfigArray = "tests_config.json" in parsedContents ? JSON.parse(parsedContents["tests_config.json"].content) : []
                    setTestsConfigDict(testsConfigArray.tests.reduce((acc, curr) => {
                        acc[curr.id] = curr;
                        return acc;
                    }, {}));
                } else if (res.status === 401) { 
                    navigateToLoginPageEnv();
                }
            } catch (error) { 
                console.log(error);
            } finally {
                setRequestLifeCycleTwo(false);
            }
        };
        
        if (zipFileExists) {
            fetchZip(); 
        }      

    }, [zipFileExists])

    function updateAssessmentInformation(assessmentInformation) {
        setAssessmentInformation(assessmentInformation);
    }

    function updateCurrentViewer(newViewer) {
        setCurrentViewer(newViewer);
    }

    function currentViewerToReturn() {
        switch(currentViewer) {
            case TypesOfLocationEnum.PDF: {
                return <PdfAssessmentPageContainerWrapper assessmentInformation={assessmentInformation}/>
            }
            case TypesOfLocationEnum.ZIP: {
                return <ZipFileViewer requestLifeCycle={requestLifeCycleTwo} contents={contents} blob={blob}/>
            }
            case TypesOfLocationEnum.CUSTOM_EXAM: {
                return <CustomExamViewGradedWrapper requestLifeCycle={requestLifeCycle} gradedQuestions={gradedQuestions} contents={contentsForCustomExam}/>
            }
            case TypesOfLocationEnum.TESTS: {
                return <TestsViewerGrading requestLifeCycle={requestLifeCycle || requestLifeCycleTwo} gradedQuestions={gradedQuestions} testsConfigDict={testsConfigDict} updateCurrentViewer={updateCurrentViewer}/>
            }
            default: {
                return <></>
            }
        }
    }

    return (
        <>
            <Navigation boolTwo={true} bool={false} info={ <NavInfoAssessment name={assessmentInformation.name}/> }/>
            <div className="courses-container" style={{ overflow: "hidden", position: width ? "relative" : "fixed" }}>
                <div className={`single-course-container viewer full ${width ? "small" : "height"}`}>
                    <div className={`assessment-page-content min ${width ? "small" : ""}`}>
                        {
                            currentViewer === TypesOfLocationEnum.SETTINGS

                            ?

                            <>
                                <LeftInfoAssessmentPage requestLifeCycle={requestLifeCycle} assessmentInformation={assessmentInformation}/>
                            </>

                            :

                            <>
                            </>
                        }
                        {
                            currentViewerToReturn()
                        }
                    </div>
                </div>
            </div>
            {
                assessmentInformation.published && email

                ?
                
                <LocationSettingsHover pdfExists={'submission' in assessmentInformation ? assessmentInformation.submission.pdfExists : false} zipFileExists={'submission' in assessmentInformation ? assessmentInformation.submission.zipFileExists : false} updateCurrentViewer={updateCurrentViewer} isProgramming={assessmentInformation.isProgramming}/>

                :

                <>
                </>
            }
        </>
    );
}

export default AssessmentPage;