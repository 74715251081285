import React from "react";
import InstructorInfo from "./instructorInfo";
import Unenroll from "./unenroll";

const NavInfoCourse = (props) => {
    return (
        <>
            <div className="semester-nav-info">
                <div className="side-nav-info-header">
                    { props.name }
                </div>
                <div className="text-side-nav">
                    { props.description }    
                </div>
                <div className="side-nav-info-header">
                    { props.instructors.length > 1 ? "Instructors" : "Instructor" }
                </div>
                <div className="meta-info-side">
                    <div className="meta-info-side-container">
                        {
                            props.instructors.map((instructor) => {
                                return <InstructorInfo key={instructor.email} instructor={instructor.name} email={instructor.email}/>
                            })
                        }
                    </div>
                </div>
                <div className="side-nav-info-header">
                    Actions
                </div>
                <div className="instructor-name wrap" style={{ cursor: "pointer" }} onClick={() => {props.updateUnenroll(true)}}>
                    <Unenroll /> Unenroll From Course
                </div>
            </div>
        </>
    );
}

export default NavInfoCourse;