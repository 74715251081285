import React, { useState } from "react";
import CourseInput from "./courseInput";
import SaveChanges from "./saveChanges";
import WhiteLoading from "./whiteLoading";
import Info from "./info";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const AddACourseForm = (props) => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);
    const [text, setText] = useState("Enter your case-senstive entry code")
    const [code, setCode] = useState("")
    const resetForm = () => {
        props.updateAdding(false);
    }

    function updateCode(code) {
        setCode(code)
    }

    const submitForm = async (e) => {
        if (props.numCourses > 5000) {
            setText("Unenroll from courses before adding more (500 max)")
            return;
        }

        e.preventDefault();
        if (code.length === 16) {
            const url = process.env.REACT_APP_COURSE_API_URL + "/join-course"
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ code })
            }

            try {
                setRequestLifeCycle(true);
                const res = await fetch(url, urlOptions);
                const data = await res.json();
                console.log(data);
                if (res.status === 200) {
                    setText("Success")
                    props.updateAdding(false);
                    window.location.reload();
                } else if (res.status === 400) {
                    setText("Code is not 16 characters")
                } else if (res.status === 409) {
                    setText("You are already enrolled in this course")
                } else if (res.status === 404) {
                    setText("Course not found")
                } else if (res.status === 401) {
                    setText("Authetication Error")
                    navigateToLoginPageEnv();
                } else {
                    setText("An error occurred");
                }
        
            } catch (error) {
                setText("An error occurred")
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
            
        } else {
            if (code.length > 16) {
                setText("Code has too many characters")
            } else {
                setText("Code has too few characters")
            }
        }
    }

    console.log(props.numCourses);

    return (
        <>
            <div className="black-back" onClick={ () => {props.updateAdding(false)} }>

            </div>
            <div className="add-a-course-form-container">
                <div className="add-a-course-form-content-container">
                    <form className="form-width">
                        <div className="add-course-title">
                            Add Course Via Entry Code
                        </div>
                        <div className="account-settings-content">
                            <div className="required-container">
                                <div className="required">
                                    *
                                </div> Required Field
                            </div>   
                            <div className="banner">
                               {
                                    requestLifeCycle

                                    ?

                                    <>
                                        <WhiteLoading />
                                    </>

                                    :

                                    <div className="banner-info">
                                        <Info />
                                        { text }
                                    </div>
                               }
                            </div>
                            <CourseInput top={"Code"} value={code} updateValue={updateCode} required={true} autoComplete={"one-time-code"} placeholder={"Sixteen Character Instructor-Provided Code"}/>   
                            <SaveChanges left="Cancel" right={{ small : "Add Course", large : "Add Course" }} resetForm={resetForm} submitForm={submitForm}/> 
                        </div>
                    </form> 
                </div>
            </div>
        </>
    );
}

export default AddACourseForm;