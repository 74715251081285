import React, { useState, useEffect, useRef } from "react";
import TrueFalseQuestionView from "./trueFalseQuestionView";
import SelectAllThatApplyView from "./selectAllThatApplyView";
import TextResponseView from "./textResponseView";
import CodeResponseView from "./codeResponseView";
import MultipleChoiceView from "./multipleChoiceView";
import TypesOfQuestionsEnum from "./typesOfQuestionsEnum";
import ContextBlockView from "./contextBlockView";
import { useParams, useNavigate } from "react-router-dom";
import CompleteOnPaperView from "./completeOnPaperView";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const CustomExamWrapperView = ({ useSyntaxHighlighting, updateContents, contents, questions, updateQuestionsToSave, grading }) => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    const ref = useRef(null);
    const navigate = useNavigate();
    const [initCall, setInitCall] = useState(false);
    const { courseCode } = useParams();
    
    useEffect(() => {
        const fetchTheme = async () => {
            try {
                const response = await fetch( process.env.REACT_APP_SUBMISSION_API_URL + `/get-theme`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsDarkTheme(data.isDarkTheme);
                } else if (response.status === 401) {
                    navigateToLoginPageEnv();
                }
            } catch (error) {
                console.log(error);
            }
        };
        
        fetchTheme();
    }, [])

    function updateIsDarkTheme(theme) {
        setIsDarkTheme(theme);        
    }

    useEffect(() => {
        if (initCall) {
            submitForm(isDarkTheme);
        } else {
            setInitCall(true);
        }
    }, [isDarkTheme])

    const submitForm = async (theme) => {
        try {
            const url = process.env.REACT_APP_SUBMISSION_API_URL  + "/update-theme";
            const urlOptions = {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    isDarkTheme: theme
                })
            }

            const response = await fetch(url, urlOptions);
            if (response.status === 401) {
                navigateToLoginPageEnv();
            }

        } catch(error) {
            console.log(error);
        }
    }

    const typeOfQuestionToReturn = (question, index) => {
        switch (question.questionType) {
            case TypesOfQuestionsEnum.TRUE_FALSE: {
                return <TrueFalseQuestionView studentAnswer={question.studentAnswer} bubbledAnswer={question.bubbled} pointsEarned={question.pointsEarned} grading={grading} bubbledPassed={question.bubbled} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.SELECT_ALL: {
                return <SelectAllThatApplyView studentAnswer={question.studentAnswer} answerArrayAnswer={question.answerArray} grading={grading} pointsEarned={question.pointsEarned} answerArrayPassed={question.answerArray} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} questionNumber={index} points={question.points} question={question.question} options={question.options}/>
            }
            case TypesOfQuestionsEnum.SHORT_RESPONSE: {
                return <TextResponseView grading={grading} studentAnswer={question.studentAnswer} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} pointsEarned={question.pointsEarned} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.SCAN_AND_GRADE: {
                return <CompleteOnPaperView grading={grading} answerPassed={question.answer} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} pointsEarned={question.pointsEarned} questionNumber={index} points={question.points} question={question.question}/>
            }
            case TypesOfQuestionsEnum.MULTIPLE_CHOICE: {
                return <MultipleChoiceView studentAnswer={question.studentAnswer} answerIndexAnswer={question.answerIndex} grading={grading} answerIndexPassed={question.answerIndex} pointsEarned={question.pointsEarned} uuid={question.uuid} updateQuestionsToSave={updateQuestionsToSave} key={question.uuid} questionNumber={index} points={question.points} question={question.question} options={question.options}/>
            }
            case TypesOfQuestionsEnum.PROGRAMMING: {
                return <CodeResponseView useSyntaxHighlighting={useSyntaxHighlighting} grading={grading} answerPassed={question.answer} uuid={question.uuid} pointsEarned={question.pointsEarned} updateQuestionsToSave={updateQuestionsToSave} location={question.location} updateContents={updateContents} contents={contents} useAutocompletion={question.useAutocompletion} key={question.uuid} questionNumber={index} points={question.points} question={question.question} language={question.language} isDarkTheme={isDarkTheme} updateIsDarkTheme={updateIsDarkTheme} usingZipFile={false}/>
            }
            case TypesOfQuestionsEnum.CONTEXT_BLOCK: {
                return <ContextBlockView grading={grading} uuid={question.uuid} key={question.uuid} questionNumber={index} points={question.points} question={question.question}/>
            }
            default: {
                return <></>
            }
        }
    }

    return (
        <>
            {
                questions && Object.keys(questions).length > 0

                ?

                <>
                    <div className="custom-exam-wrapper-view" ref={ref}>
                        {
                            questions
                                .map((question, index) => (
                                        typeOfQuestionToReturn(question, index + 1)
                                )                                    
                            )
                        }
                    </div>
                </>

                :

                <>
                    <div className="no-questions">
                        <div className="no-assignments-container" style={{ paddingTop: "0px"}}>
                            <div className="no-assignments"> 
                                There are currently no questions
                                <div className="save-changes-2" onClick={() => {
                                    navigate(`/${courseCode}`);
                                }}>
                                    Back To Course
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default CustomExamWrapperView;