import React from "react";
import MarkdownWithLaTeX from "./markDownWithLatex";

const ContextBlockView = ({ questionNumber, question, points }) => {

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        C{ questionNumber }
                    </>
                    <div className="pts">
                        { points } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} />
                </div>
            </div>
        </>
    );
}

export default ContextBlockView;