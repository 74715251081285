import React, { useState } from "react";
import CourseCard from "./courseCard";
import Plus from "./plus";
import useWindowWidth from "./useWindowWidth";
import AddACourseForm from "./addACourseForm";

const Semester = (props) => {
    let widthValue = "31%";
    let columnGap = "2.8%"
    const width = useWindowWidth(1200);
    const widthTwo = useWindowWidth(770);
    const [adding, setAdding] = useState(false);

    if (width) {
        if (widthTwo) {
            widthValue = "100%";
        } else {
            widthValue = "47%";
            columnGap = "4.5%";
        }
    }

    const updateAdding = (bool) => {
        setAdding(bool)
    }

    return (
        <>
            <div className={ props.index === 0 ? "semester-period" : "semester-period-2" }>
                { props.time }
            </div>
            <div className="courses-container" style={{ "columnGap": `${columnGap}` }}>
                {
                    props.courses.map((course) => {
                        return <CourseCard key={course.courseCode} color={course.color} courseCode={course.courseCode} name={course.name} description={course.description} numAssessments={course.numAssessments}/>
                    })
                }

                {   
                    props.index === 0

                    ?

                        <div className="add-course-card" style={{ width: `${widthValue}` }} onClick={ () => {updateAdding(true)} }>
                            <Plus bool={true}/>
                            Add a Course
                        </div>

                    :

                    <>
                    </>

                }

                { adding ? <AddACourseForm numCourses={props.numCourses} updateAdding={updateAdding}/> : <></> }
            </div>
        </>
    );
}

export default Semester;