import React from "react";

const CustomSwitch = (props) => {
    return (
        <>
            {
                props.using

                ?

                <>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDisabled" checked disabled />
                    </div>
                </>

                :

                <>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedDisabled" disabled />
                    </div>
                </>

            }
        </>
    );
}

export default CustomSwitch;