import React, { useState, useEffect } from "react";
import BubbleWithTextView from "./bubbleWithTextView";
import MarkdownWithLaTeX from "./markDownWithLatex";
import BubbleWithTextViewGradingPage from "./bubbleWithTextViewGradingPage"

const TrueFalseQuestionView = ({ questionNumber, question, points, grading, updateQuestionsToSave, uuid, bubbledPassed, studentAnswer, bubbledAnswer, pointsEarned }) => {
    const [bubbled, setBubbled] = useState(bubbledPassed);
    const [initCall, setInitCall] = useState(true);

    function updateBubbled(newBubbled) {
        if (newBubbled === bubbled) {
            setBubbled(-1);
        } else {
            setBubbled(newBubbled);
        }
    }

    useEffect(() => {
        // init checking
        if (initCall) {
            setInitCall(false);
            return;
        }

        // construct new body
        const body = {
            bubbled: bubbled
        };

        // updateQuestionsToSave
        updateQuestionsToSave((prev) => ({
        ...prev,
        [uuid]: body
        }))

    }, [bubbled])

    const handleStateCalculation = (answerState) => {
        // check if answers match or check if they match the possible answerState
        if (studentAnswer === bubbledAnswer && (bubbledAnswer === answerState)) {
            return 1;
        } 
        // check if the answers dont't match and the correct answer state is a match
        else if (studentAnswer !== bubbledAnswer && (studentAnswer === answerState)) {
            return 0;
        }
        // if answered wrong and correct
        else if (studentAnswer !== bubbledAnswer && (bubbledAnswer === answerState)) {
            return 2;
        }
        // if both fail, we have a blank answer
        else {
            return -1;
        }
    }

    return (
        <>
            <div className="true-false-question-view">
                <div className="question-number">
                    <>
                        Q{ questionNumber }
                    </>
                    <div className="pts">
                        { (grading ? Number(pointsEarned).toFixed(2) + " / " : "") + Number(points).toFixed(2) } pts
                    </div>
                </div>
                <div className="true-false-top">
                    <MarkdownWithLaTeX content={question} />
                </div>
                <div className="true-false-answer-block">
                    {
                        grading

                        ?

                        <>
                            <BubbleWithTextViewGradingPage text={"True"} state={handleStateCalculation(1)}/>
                            <BubbleWithTextViewGradingPage text={"False"} state={handleStateCalculation(0)}/>
                        </>

                        :

                        <>
                            <BubbleWithTextView text={"True"} bubbled={bubbled === 1} updateBubbled={updateBubbled}/>
                            <BubbleWithTextView text={"False"} bubbled={bubbled === 0} updateBubbled={updateBubbled}/>
                        </>

                    }
                </div>
            </div>
        </>
    );
}

export default TrueFalseQuestionView;