import React, { useState, useEffect } from "react";
import Semester from "./semester";
import ReformatCourses from "./reformatCourses";
import Loading from "./loading";
import getCurrentSeasons from "./getCurrentSeason";
import useNavigateToLoginPageEnv from "./useNavigateToLoginPageEnv";

const Dashboard = (props) => {
    const navigateToLoginPageEnv = useNavigateToLoginPageEnv();
    const [semesters, setSemesters] = useState([]);
    const [requestLifeCycle, setRequestLifeCycle] = useState(false);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                setRequestLifeCycle(true);
                const res = await fetch( process.env.REACT_APP_COURSE_API_URL  + "/get-courses", {
                    method: "GET",
                    credentials: "include"
                });
                const data = await res.json();
                if (res.status === 200) {
                    props.updateNumCourses(data.courses.length)
                    setSemesters(ReformatCourses(data.courses))
                } else if (res.status === 401) {
                    navigateToLoginPageEnv();
                }
                console.log(res)
            } catch (error) {
                console.log(error);
            } finally {
                setRequestLifeCycle(false);
            }
        }

        fetchCourses();

    }, []);

    const handleNoSemesters = () => {
        if (semesters && semesters.length === 0) {
            return  <Semester numCourses={props.numCourses} index={0} courses={[]} time={getCurrentSeasons()}/>
        }
    }

    return (
        <>
            <div className="courses-container">
                <div className="header">
                    <div className="courses-container-2">
                        <div className="your-courses">
                            Your Courses
                        </div>
                        {
                            requestLifeCycle

                            ?

                            <>
                                <div className="loading-container" style={{ height: "400px" }}>
                                    <Loading />
                                </div>  
                            </>

                            :

                            <>
                                {
                                    handleNoSemesters()
                                }
                            </>                                                                            

                        }
                        {
                            semesters && semesters.length > 0

                            ?

                            <>
                                {
                                    semesters.map((semester, index) => {
                                        return <Semester numCourses={props.numCourses} key={semester.courses[0].courseCode} time={semester.time} courses={semester.courses} index={index}/>
                                    })
                                }
                            </>

                            :

                            <>
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;