import React from "react";
import Instructor from "./instructor";

const InstructorInfo = (props) => {
    return (
        <>
            <div className="instructor-name">
                <Instructor /> { props.instructor }
            </div>
        </>
    );
}

export default InstructorInfo;