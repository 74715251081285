import React from "react";
import { useParams, useLocation } from "react-router-dom";

const DownloadButton = ({ blob }) => {
    const { email } = useParams();
    const location = useLocation();

    const handleClick = () => {
        const urlBlob = URL.createObjectURL(blob); // Create a URL for the Blob

        // Create a link element
        const a = document.createElement('a');
        a.href = urlBlob;
        a.download = email + "-submission-zip"; // Name of the downloaded file

        // Append the link to the body
        document.body.appendChild(a);
        a.click(); // Programmatically click the link to trigger the download

        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(urlBlob); // Revoke the Blob URL
    }

    return (
        <>  
            <div className="zip-download-wrapper">
                <div className="zip-download-container" onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                    </svg>
                </div>
            </div>
        </>
    );
}

export default DownloadButton;